body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #fff;
}

#root {
  overflow-x: hidden;
  height: 100dvh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg.star-svg {
  display: inline;
}

.react-calendar-heatmap .color-scale-1 { fill: #FFD1DC; }
.react-calendar-heatmap .color-scale-2 { fill: #FFB6C1; }
.react-calendar-heatmap .color-scale-3 { fill: #FF9BB3; }
.react-calendar-heatmap .color-scale-4 { fill: #FF80A6; }
.react-calendar-heatmap {
  pointer-events: none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: 100vw;
  aspect-ratio: 1/1;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  object-position: center;
}

.image-gallery-slide {
  width: 100vw;
}

.image-gallery-icon {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 1)) !important;
}

.image-gallery-bullets {
  top: 10px !important;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 1) !important;
}
